<template>
  <div>
    <page-header icon="fa fa-th-list" :title="title" :links="getLinks()"></page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div ref="printMe" v-if="!isLoading(loadingName)">
      <div class="individuals-list" ref="excelMe">
        <x-table
          :columns="columns"
          :items="items"
          :show-counter="true"
          verbose-name="individu"
          @selectionChanged="onSelectionChanged($event)"
        ></x-table>
      </div>
    </div>
    <confirm-modal
      icon="fas fa-trash"
      name="archive-single-individuals"
      title="Archiver les membres sélectionnés"
      :text="getArchiveMessage"
      @confirmed="confirmArchive()"
    >
    </confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import XTable from '@/components/Controls/Table/Table.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'
import { BackendMixin } from '@/mixins/backend'
import { makeIndividual } from '@/types/people'
import { BackendApi, openDocument } from '@/utils/http'

export default {
  name: 'SingleIndividuals',
  mixins: [BackendMixin],
  components: {
    ConfirmModal,
    PageHeader,
    LoadingGif,
    XTable,
  },
  props: {
  },
  data() {
    return {
      loadingName: 'single-individuals',
      selectedItem: null,
      individuals: [],
      columns: [],
      selection: [],
    }
  },
  mounted() {
    this.columns = this.getColumns()
    this.loadData()
  },
  computed: {
    title() {
      return 'Liste des individus hors famille et structures'
    },
    items() {
      return this.individuals.map(
        elt => this.makeItem(elt)
      )
    },
    getArchiveMessage() {
      if (this.selection.length === 1) {
        return 'Voulez-vous archiver ' + this.selection[0].fullName + '?'
      } else {
        return 'Voulez-vous archiver les ' + this.selection.length + ' membres sélectionnés?'
      }
    },
  },
  methods: {
    ...mapActions(['addSuccess', 'addError', 'addWarning']),
    ...mapMutations(['startLoading', 'endLoading']),
    getColumns() {
      return [
        { selector: true, 'name': 'selector', },
        {
          name: 'lastName',
          label: 'Nom',
          maxWidth: '100px',
        },
        {
          name: 'firstName',
          label: 'Prénom',
          maxWidth: '100px',
        },
        {
          name: 'email',
          label: 'Email',
          displayAs: 'email',
          maxWidth: '150px',
        },
        {
          name: 'cellPhone',
          label: 'Téléphone portable',
          alignCenter: true,
          maxWidth: '120px',
          displayAs: 'phone',
        }
      ]
    },
    onSelectionChanged(event) {
      this.selection = event.items
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'liste-individus'
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'liste-individus'
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      const isDisabled = this.isLoading(this.loadingName) || (this.selection.length === 0)
      const links = [
        {
          id: 1,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'far fa-file-excel',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
      if (this.hasPerm('people.delete_individual')) {
        links.push(
          {
            id: 3,
            label: 'Archiver',
            callback: this.archiveMembers,
            icon: 'fas fa-trash',
            cssClass: isDisabled ? 'btn-secondary disabled' : 'btn-secondary',
          }
        )
      }
      return links
    },
    makeItem(elt) {
      return {
        id: elt.id,
        fullName: elt.fullName(),
        lastName: elt.lastName,
        firstName: elt.firstName,
        email: elt.email,
        cellPhone: elt.cellPhone,
        individualId: elt.id,
      }
    },
    async loadData() {
      let url = '/api/people/single-individuals/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.individuals = resp.data.map(elt => makeIndividual(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    archiveMembers() {
      this.$bvModal.show('bv-confirm-modal:archive-single-individuals')
    },
    async confirmArchive() {
      let url = '/api/people/single-individuals/'
      const data = {
        individuals: this.selection.map(elt => elt.id),
      }
      const backendApi = new BackendApi('post', url)
      try {
        const resp = await backendApi.callApi(data)
        await this.loadData()
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style scoped>
</style>
